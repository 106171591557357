import React from "react";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

const VLGConceptComponent = ({ data }) => {
  return (
    <section className="stf-vlg-concept">
      <div className="stf-container">
        <a 
          href={data.visual.link} 
          target="_blank" rel="noreferrer" className="stf-vlg-concept__source"
        >
          <div className="stf-vlg-concept__visual">
            <RenderImage
              image={data.visual.desktop}
              modifiers={{
                className: "stf-vlg-concept__visual--desktop",
              }}
            />
            <RenderImage
              image={data.visual.mobile}
              modifiers={{
                className: "stf-vlg-concept__visual--mobile",
              }}
            />
          </div>
        </a>
        <p className="stf-vlg-concept__visual__copyright">{data.visual.copyright}</p>

        <h2>{data.title}</h2>
        <div className="stf-vlg-concept__text">
          <RenderMD content={data.text} />
        </div>
      </div>
    </section>
  );
};
export default VLGConceptComponent;
