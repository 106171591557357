import React from "react";
import { graphql } from "gatsby";

// JS Logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import VLGConceptComponent from "~components/our-work/vlgConcept";

const ErrorPage = ({ data, location }) => {
  const {
    frontmatter: { locale: localeKey, metadata, headerOverride, vlgConcept },
  } = data.markdownRemark;

  const locale = getLocale(localeKey);

  return (
    <Layout
      location={location}
      locale={locale}
      pageMetadata={metadata}
      pageHeader={headerOverride}
      hideScrollText={true}
    >
      <VLGConceptComponent data={vlgConcept} />
    </Layout>
  );
};

export default ErrorPage;

export const pageQuery = graphql`
  query OurWorkPage($locale: String) {
    markdownRemark(
      frontmatter: {
        templateKey: { eq: "our-work-page" }
        locale: { eq: $locale }
      }
    ) {
      frontmatter {
        locale
        metadata {
          title
          description
        }
        headerOverride {
          img {
            name
            childImageSharp {
              fluid(maxWidth: 1080, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          text
          button {
            text
            path
          }
        }

        vlgConcept {
          visual {
            desktop {
              name
              publicURL
            }
            mobile {
              name
              publicURL
            }
            link
            copyright
          }
          title
          text
        }
      }
    }
  }
`;
